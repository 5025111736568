<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <span>
            部门：
            <DeptCascader
              :placeholder="'请选择部门'"
              :deptList="deptList"
              v-model="parameter.deptId"
            />
          </span>

          <span
            >工时日期：
            <el-date-picker
              class="date_width"
              v-model="createdDate"
              :pickerOptions="pickerOptions"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker
          ></span>
          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <!-- <el-button type="primary" icon="el-icon-edit" @click="clear_serach()">清空</el-button> -->
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="left" type="index" label="序号"></el-table-column>
            <el-table-column align="left" prop="departmentName" label="部门"></el-table-column>
            <el-table-column align="left" prop="sumhour" label="工时"></el-table-column>
            <el-table-column prop="title" align="center" width="70" fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click.stop="detail(scope.row)"
                  >详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { encrypt, decrypt } from '@/util/jsencrypt'
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        deptId: '',
      },
      form: {},
      tableData: [],
      loading: false,
      createdDate: [],
      deptList: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.get_DeptList()
  },
  watch: {
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },

  methods: {
    getData() {
      this.loading = true
      if (this.createdDate) {
        this.parameter.beginDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      }
      this.$api.hour
        .getAllMahhoursByDept(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = res.data.records
          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    detail(row) {
      let departmentId = encrypt(row.departmentId)
      this.$router.push({
        path: '/hour/byProject',
        query: {
          deptId: departmentId,
          createdDate: JSON.stringify(this.createdDate),
        },
      })
    },
    handleReset() {
      this.createdDate = []
      this.parameter = {
        pageNow: 1,
        pageSize: this.parameter.pageSize,
        total: 0,
        deptId: '',
      }
      if (this.status == 3) {
        this.getData2()
      } else {
        this.getData()
      }
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    dataUpdate(row) {
      this.$forceUpdate()
    },
    get_DeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
